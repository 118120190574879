import axios from 'axios';
import { getToken, transformObjectToQueryParams } from '../../utils';

const api = axios.create({
  baseURL: 'https://api.andandonoprofetico.com',
});

api.interceptors.request.use(
  (config) => {
    const token = getToken();
    // eslint-disable-next-line no-param-reassign
    if (token) config.headers.authorization = `Bearer ${token}`;
    return config;
  },
  (error) => Promise.reject(error)
);

export const auth = {
  authentication: (params: any) =>
    api.post('/v1/authentications/employee', params),
};

export const user = {
  listMe: () => api.get('v1/internal-users/me'),
};

export const password = {
  reset: (params: any) => api.post('/v1/users/change-password', params),
};

export const product = {
  listAll: (params: any) =>
    api.get(`v1/products?${transformObjectToQueryParams(params)}`),
  create: (params: any) => api.post('/v1/products', params),
  listById: (id: string) => api.get(`/v1/products/${id}`),
  delete: (id: string) => api.delete(`/v1/products/${id}`),
  update: (id: string, params: any) => api.put(`/v1/products/${id}`, params),
  listByName: (name: string) => api.get(`v1/products/name/${name}`),
};

export const storiesService = {
  listAll: () => api.get('/v1/stories'),
  delete: (id: number) => api.delete(`/v1/stories/${id}`),
  create: (params: any) => api.post('/v1/stories/', params),
};

export const channels = {
  listAll: (params: any) =>
    api.get(`v1/channels?${transformObjectToQueryParams(params)}`),
  create: (params: any) => api.post('/v1/channels', params),
  delete: (id: string) => api.delete(`/v1/channels/${id}`),
  update: (id: string, params: any) => api.put(`/v1/channels/${id}`, params),
  refresh: (id: string) => api.put(`/v1/channels/${id}/refresh`),
  register: (id: string) => api.post(`/v1/channels/${id}/videos/registers`),
};

export const calendar = {
  listAll: (params: any) =>
    api.get(`v1/diaries?${transformObjectToQueryParams(params)}`),
  create: (params: any) => api.post('/v1/diaries', params),
  delete: (id: string) => api.delete(`/v1/diaries/${id}`),
};

export const events = {
  listAll: (params: any) =>
    api.get(`v1/courses?${transformObjectToQueryParams(params)}`),
  create: (params: any) => api.post('/v1/courses', params),
  listById: (id: string) => api.get(`v1/courses/${id}`),
  delete: (id: string) => api.delete(`/v1/courses/${id}`),
  listByName: (name: string) => api.get(`v1/courses/name/${name}`),
  update: (id: string, params: any) => api.put(`/v1/courses/${id}`, params),
};

export const image = {
  create: (params: any) =>
    api.post('v1/images/upload', params, {
      headers: {
        'content-type': 'multipart/form-data',
      },
    }),
};

export const productType = {
  listAll: () => api.get('v1/product-types'),
  create: (params: any) => api.post('v1/product-types', params),
};

export const eventsType = {
  listAll: () => api.get('v1/course-types'),
  create: (params: any) => api.post('v1/course-types', params),
};

export const clients = {
  listAll: (params: any) =>
    api.get(`v1/users?${transformObjectToQueryParams(params)}`),
  listById: (id: string) => api.get(`v1/users/${id}`),
  listDeviceByUserId: (id: string) => api.get(`v1/users/${id}/devices`),
};

export const message = {
  listAll: (params: any) =>
    api.get(`v1/messages?${transformObjectToQueryParams(params)}`),
  listById: (id: string) => api.get(`/v1/messages/${id}`),
  delete: (id: string) => api.delete(`/v1/messages/${id}`),
};

export const notifications = {
  listAll: (params: any) =>
    api.get(`v1/notifications?${transformObjectToQueryParams(params)}`),
  create: (params: any) => api.post('/v1/notifications', params),
  delete: (id: string) => api.delete(`/v1/notifications/${id}`),
};

export const batch = {
  create: (params: any) => api.post('/v1/notifications/batch', params),
};

export const shipments = {
  listAll: (id: string) => api.get(`v1/notifications/${id}/shipments`),
};

export const appUser = {
  listById: (id: string) => api.get(`v1/users/${id}`),
};

export const graph = {
  listHome: () => api.get('v1/graphs'),
  listMessage: () => api.get('v1/graphs/messages'),
  listChannelNotification: () => api.get('v1/graphs/channels/notifications'),
  listChannel: () => api.get('v1/graphs/channels'),
  listShipments: () => api.get('v1/graphs/shipments'),
  listProducts: () => api.get('v1/graphs/products'),
  listEvents: () => api.get('v1/graphs/events'),
  listUserMetric: () => api.get('v1/graphs/users/metric'),
};

export const notificationChannel = {
  listAll: (id: string) => api.get(`v1/channels/${id}/notifications`),
  create: (id: string, params: any) =>
    api.post(`v1/channels/${id}/notifications`, params),
  update: (id: string, params: any) =>
    api.put(`v1/configs/notifications/${id}`, params),
};

export const reports = {
  products: () => api.get('v1/graphs/access/products'),
  events: () => api.get('v1/graphs/access/events'),
  users: () => api.get('v1/graphs/users/downloads'),
  forms: (alias: string) => api.get(`v1/forms/${alias}/downloads`),
};

export const form = {
  list: () => api.get('v1/forms'),
};

export const tickets = {
  listAll: () => api.get('v1/courses/platforms'),
};

export const participants = {
  listById: (id: string, courseId: string) =>
    api.get(`v1/courses/${courseId}/participants/${id}`),
  listByDocument: (document: string, courseId: string) =>
    api.get(`v1/courses/${courseId}/participants?document=${document}`),
  listByName: (name: string, courseId: string) =>
    api.get(`v1/courses/${courseId}/participants?name=${name}`),
  checkIn: (id: string, courseId: string) =>
    api.put(`v1/courses/${courseId}/participants/${id}`),
};

export const comment = {
  create: (params: any, id: string) =>
    api.post(`v1/messages/${id}/comments`, params),
  delete: (id: string) => api.delete(`v1/comments/${id}`),
};
