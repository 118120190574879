import { toast } from 'react-toastify';
import { requestError } from '../../../utils/error';
import { Dispatch } from '../../../domain/redux/action';
import {
  FETCH_COMMENT,
  DELETE_COMMENT,
} from '../../../infra/redux/comment/constants';
import { comment } from '../../../infra/http/web-service';
import { listMessageById } from '../messages';

export const deleteComment = (params: any) => async (dispatch: Dispatch) => {
  dispatch({ type: FETCH_COMMENT });
  try {
    const { data } = await comment.delete(params.id);

    toast.success(data.message);
    await listMessageById({ id: params.messageId })(dispatch);

    dispatch({ type: DELETE_COMMENT, payload: { id: params.id } });
  } catch (error: any) {
    requestError(error);
  } finally {
    dispatch({ type: FETCH_COMMENT });
  }
};
