import { ActionsType } from '../../../domain/redux/action';
import { CommentsReducer } from '../../../domain/redux/reducers';
import {
  CLEAN_COMMENT,
  DELETE_COMMENT,
  FETCH_COMMENT,
  CREATE_COMMENT,
} from './constants';

const INITIAL_STATE = {
  isFetch: false,
} as CommentsReducer;

const commentsReducer = (state = INITIAL_STATE, action: ActionsType) => {
  switch (action.type) {
    case CREATE_COMMENT:
      return {
        ...state,
        ...action.payload,
        isFetch: state.isFetch,
      };
    case DELETE_COMMENT:
      return {
        ...state,
        isFetch: state.isFetch,
      };
    case CLEAN_COMMENT:
      return INITIAL_STATE;
    case FETCH_COMMENT:
      return {
        ...state,
        isFetch: !state.isFetch,
      };
    default:
      return state;
  }
};

export { commentsReducer };
