import {
  Button,
  Drawer,
  DrawerBody,
  DrawerCloseButton,
  DrawerContent,
  DrawerFooter,
  DrawerHeader,
  DrawerOverlay,
  IconButton,
  Spinner,
  useDisclosure,
} from '@chakra-ui/react';
import React from 'react';
import { FaDownload } from 'react-icons/fa';
import { useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import Paragraph from '../../../components/paragraph';

import { Container, AutoLayout, Badge } from './styled';
import { useAppDispatch, useAppSelector } from '../../../../data/hooks/redux';
import { getColorMessage, getStringValue } from '../../../../utils/messages';
import { screenPrinting } from '../../../../utils/print';
import ModalComponent from '../modal';
import { formatDate } from '../../../../utils';
import TextArea from '../../../components/text-area';

import { createCommentSchema } from '../../../../validation/forms';
import { createComment } from '../../../../data/usecases/comment/create-comment';
import { deleteComment } from '../../../../data/usecases/comment/delete-comment';

export type ModelDto = {
  isOpen: boolean;
  onClose: () => void;
  removeMessage: (id: string) => void;
};

const DrawerContainer: React.FC<ModelDto> = ({
  isOpen,
  onClose,
  removeMessage,
}) => {
  const {
    isOpen: isOpenModal,
    onOpen,
    onClose: onCloseModal,
  } = useDisclosure();
  const messages = useAppSelector((state) => state.messages);
  const comments = useAppSelector((state) => state.comments);
  const dispatch = useAppDispatch();

  const onPrint = () => {
    screenPrinting(messages.item?.message?.external_id || 'mensagens');
  };

  const onRemove = () => {
    removeMessage(messages.item?.message?.external_id);
    onCloseModal();
  };

  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm<any>({
    resolver: yupResolver(createCommentSchema),
  });

  const onSubmit = (value: any) => {
    dispatch(
      createComment({
        body: value,
        messageId: messages.item?.message?.external_id,
      })
    );
  };

  const onDeleteComment = () => {
    dispatch(
      deleteComment({
        id: messages.item?.comment?.external_id,
        messageId: messages.item?.message?.external_id,
      })
    );
  };

  return (
    <>
      <Drawer isOpen={isOpen} placement="right" size="md" onClose={onClose}>
        <DrawerOverlay />
        <DrawerContent>
          <DrawerCloseButton />
          <DrawerHeader borderBottomWidth="1px">Informações</DrawerHeader>

          <DrawerBody>
            <div id="print">
              {messages.item && (
                <>
                  <AutoLayout>
                    <h2>Usuário</h2>

                    <Paragraph align="left" fontSize="small" label="Nome">
                      {messages.item?.user?.name}
                    </Paragraph>

                    <Paragraph align="left" fontSize="small" label="E-mail">
                      {messages.item?.user?.email}
                    </Paragraph>

                    <Paragraph
                      align="left"
                      fontSize="small"
                      label="Usuário desde:"
                    >
                      {formatDate(messages.item?.user?.created_at)}
                    </Paragraph>
                  </AutoLayout>

                  <AutoLayout>
                    <h2>Mensagem</h2>

                    <Paragraph align="left" fontSize="small" label="Criado em:">
                      {formatDate(messages.item?.message?.created_at)}
                    </Paragraph>

                    <Badge colorType={getColorMessage(messages.item.type.type)}>
                      {getStringValue(messages.item.type.type)}
                    </Badge>

                    <Container>{messages.item?.message?.text}</Container>
                  </AutoLayout>

                  <AutoLayout>
                    {messages.item?.type?.type === 'COMMUNITY' &&
                      !comments.isFetch && (
                        <>
                          <h2>Resposta</h2>

                          {messages.item?.comment?.external_id ? (
                            <>
                              <Paragraph
                                align="left"
                                fontSize="small"
                                label="Respondido em:"
                              >
                                {formatDate(messages.item?.comment?.created_at)}
                              </Paragraph>
                              <Container>
                                {messages.item?.comment?.text}
                              </Container>

                              <Button
                                marginBottom={10}
                                marginTop={5}
                                colorScheme="red"
                                onClick={onDeleteComment}
                              >
                                Excluir resposta
                              </Button>
                            </>
                          ) : (
                            <form onSubmit={handleSubmit(onSubmit)}>
                              <TextArea
                                label="Digite a resposta"
                                placeholder="Encaminhe uma resposta ao usuário"
                                {...register('text')}
                                error={errors.text}
                              />

                              <Button
                                marginBottom={10}
                                colorScheme="blue"
                                type="submit"
                              >
                                Enviar resposta
                              </Button>
                            </form>
                          )}
                        </>
                      )}

                    {comments.isFetch && <Spinner colorScheme="blue" />}
                  </AutoLayout>
                </>
              )}
            </div>
          </DrawerBody>

          <DrawerFooter borderTopWidth="1px">
            <IconButton
              icon={<FaDownload />}
              aria-label="Download da mensagem"
              variant="outline"
              colorScheme="blue"
              onClick={onPrint}
            />
            <Button marginLeft={3} colorScheme="red" onClick={onOpen}>
              Deletar
            </Button>
          </DrawerFooter>
        </DrawerContent>
      </Drawer>
      <ModalComponent
        isOpen={isOpenModal}
        onClose={onCloseModal}
        removeMessage={onRemove}
      />
    </>
  );
};

export default DrawerContainer;
